import React from 'react'
import * as yup from 'yup'
import { ObjectSchema } from 'yup'

import { Alert, Button, LoadingOverlay, PageHeader } from '../../common'
import { Input, Select } from '../../common/form/fields'
import { useForm, useIndustries, useProfile } from '../../../hooks'
import { Industry } from '../../../hooks/useIndustries/useIndustries.type'
import { UserRoles } from '../../../common/utils/roles'
import { Severity } from '../../../types'

import * as styles from './profile.module.scss'
import { isEmptyObj } from '../../common/form/helper'

const buildValidationSchema = (
   industries: Industry[],
   roles: number[]
): ObjectSchema<any> => {
   const isEmployee = roles?.includes(UserRoles.EMPLOYEE)
   const baseRulesObj = {
      Firstname: yup.string().required(),
      Lastname: yup.string().required(),
      PhoneNumber: yup.string(),
      AddressLine1: yup.string(),
      AddressLine2: yup.string(),
      AddressLine3: yup.string(),
      City: yup.string(),
      Country: yup.string(),
      State: yup.string(),
      ZipCode: yup.string(),
   }
   const extraRulesObj = {
      IndustryId: yup
         .string()
         .test(
            'is-valid-option',
            'Please choose a valid industry',
            function (value) {
               return !!industries.find(
                  (industry) => industry.Id === parseInt(value)
               )
            }
         )
         .required('Industry is a required field'),
      Company: yup.string().required(),
   }

   return yup
      .object()
      .shape(isEmployee ? baseRulesObj : { ...baseRulesObj, ...extraRulesObj })
}

export default function MyDetails() {
   const { industries } = useIndustries()
   const {
      isSavingDetails,
      isDetailsUpdatedSuccess,
      profile,
      updateProfileDetails,
   } = useProfile()
   const { onSubmit, validationErrors } = useForm(
      buildValidationSchema(industries, profile?.Roles),
      (values) => {
         updateProfileDetails({
            ...values,
            // this is a bit of a workaround due to naming inconsistencies from API GET and POST
            address1: values.AddressLine1,
            address2: values.AddressLine2,
            address3: values.AddressLine3,
         })
      }
   )

   const isEmployee = profile?.Roles?.includes(UserRoles.EMPLOYEE)
   const industryOptions = industries?.map(({ Id, Name }) => ({
      key: Id,
      value: Name,
   }))
   const showProfileForm = !isEmptyObj(profile) && industryOptions.length

   return (
      <>
         <PageHeader title="Details" variant="wideMargin" />

         {!showProfileForm ? (
            <LoadingOverlay />
         ) : (
            <form noValidate onSubmit={onSubmit}>
               <div className={styles.profile}>
                  <div className={styles.profileColumn}>
                     <Input
                        id="Firstname"
                        label="First Name"
                        value={profile.Firstname}
                        error={validationErrors.Firstname}
                        required
                     />
                     <Input
                        id="Lastname"
                        label="Last Name"
                        value={profile.Lastname}
                        error={validationErrors.Lastname}
                        required
                     />
                     <Input
                        description="If you are the primary administrator for your company, this email address cannot be changed. Contact ShareMyToolbox support. If this is a secondary administrator, the primary admin can change the email by editing the admin on the connections tab."
                        id="Email"
                        label="Email"
                        value={profile.Email}
                        error={validationErrors.Email}
                        required
                        disabled
                     />
                     <Input
                        id="PhoneNumber"
                        label="Phone Number"
                        value={profile.PhoneNumber}
                        error={validationErrors.PhoneNumber}
                     />
                     {!isEmployee && (
                        <>
                           <Select
                              id="IndustryId"
                              label="Industry"
                              options={industryOptions}
                              value={profile.IndustryId}
                              error={validationErrors.IndustryId}
                              required
                           />
                           <Input
                              id="Company"
                              label="Company"
                              value={profile.Company}
                              error={validationErrors.Company}
                              required
                           />
                        </>
                     )}
                  </div>
                  <div className={styles.profileColumn}>
                     <Input
                        id="AddressLine1"
                        label="Address Line 1"
                        value={profile?.Address?.AddressLine1}
                        error={validationErrors.AddressLine1}
                     />
                     <Input
                        id="AddressLine2"
                        label="Address Line 2"
                        value={profile?.Address?.AddressLine2}
                        error={validationErrors.AddressLine2}
                     />
                     <Input
                        id="AddressLine3"
                        label="Address Line 3"
                        value={profile?.Address?.AddressLine3}
                        error={validationErrors.AddressLine3}
                     />
                     <Input
                        id="City"
                        label="City"
                        value={profile?.Address?.City}
                        error={validationErrors.City}
                     />
                     <Input
                        id="State"
                        label="State/Province"
                        value={profile?.Address?.State}
                        error={validationErrors.State}
                     />
                     <Input
                        id="Country"
                        label="Country"
                        value={profile?.Address?.Country}
                        error={validationErrors.Country}
                     />
                     <Input
                        id="ZipCode"
                        label="Postal Code"
                        value={profile?.Address?.ZipCode}
                        error={validationErrors.ZipCode}
                     />
                  </div>
               </div>

               {isDetailsUpdatedSuccess && (
                  <Alert
                     message="Your profile details have been updated successfully"
                     severity={Severity.SUCCESS}
                  />
               )}

               <Button loading={isSavingDetails}>Save</Button>
            </form>
         )}
      </>
   )
}
