import React from 'react';

import MyDetails from '../../components/views/profile/mydetails';
import { LayoutLoggedIn } from '../../components/views/layout';

export default function() {
    return (
        <LayoutLoggedIn>
            <MyDetails />
        </LayoutLoggedIn>
    );
}